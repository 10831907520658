.breadcrumb {
    font-size: 14px;
}

.breadcrumb > ol {
    padding: 0;
}

.breadcrumb > ol > li > a {
    color: #666B78;
}