@import "../Global.module.scss";

.destinationBox {
  margin: 0 12px 0 0;
}

.destinationForm {
  padding: 24px 0;
}

.permissionsContainer {
  padding: 24px 0;
  flex-direction: column;
}

.permissionsContainer > h3 {
  padding: 0 0 12px 0;
  font-weight: bold;
}

.checkboxInput {
  padding: 0 24px 0 0;
}

.actionContainer {
  justify-content: space-between;
  margin-top: 12px;
}

.errorMessage {
  padding: 8px;
  color: rgb(196, 0, 0);
}