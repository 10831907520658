.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 0;
}

.container {
  display: flex;
  flex-direction: column;
  // width: 90vw;
  margin: 32px;
}

.mutateContainer {
  display: flex;
  flex-direction: column;
  // width: 80vw;
  margin: 32px;
}

.table {
  width: 100%;
}

.createBtnContainer {
  padding: 0 0 16px 64px;
  flex-direction: row-reverse;
}

.dataTableContainer {
  width: 100%;
}

.title {
  font-size: 32px;
  font-weight: normal;
  padding-bottom: 24px;
}

.subHeading {
  padding: 24px 0 16px 0;
  font-size: 24px;
  font-weight: normal;
}

.label {
  margin: 16px 0 0 0;
}

.input {
  margin: 8px 0;
  background-color: white !important;
}

.select {
  background-color: white;
}

.actionButton {
  margin: 0 0 0 8px;
}

.na {
  color: #666b78;
  font-style: italic;
}
