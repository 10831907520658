.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  cursor: pointer;
  color: #818181;
  transition: outline 0.2s ease-in-out;
  padding: 48px;
}

.box:hover {
  color: black;
  outline: 4px solid #4f44e0;
  border: 1px solid transparent;
}

.boxSelected {
  color: black;
  outline: 4px solid #4f44e0;
  border: 1px solid transparent;
}

.image {
  width: 128px;
  height: auto;
  padding: 0 0 12px 0;
}

.infoContainer {
  background-color: white;
  cursor: pointer;
  border: 1px solid var(--chakra-colors-chakra-border-color);
  border-radius: var(--chakra-radii-xl);
  padding: 12px 16px;
  max-width: 400px;
  font-size: 14px;
}

.infoImage {
  width: 50px;
  height: auto;
  margin-right: 20px;
}

.infoHeader {
  font-size: 14px !important;
  margin-bottom: 0;
}

.infoDesc {
  font-weight: normal;
  margin-bottom: 5px;
}

.infoPath {
  font-size: 12px;
}