.container {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.page {
  flex-direction: column;
}

.header {
  width: 100%;
  background-color: white;
  border-bottom: 2px solid #EEF0F3;
  padding: 8px 0 8px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.heading {
  font-family: 'Katibeh' !important;
  font-weight: bold !important;
  font-size: 34px !important;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;

  // due to the font family text baseline, have to displace slightly in order to veritically align the text
  position: relative;
  top: 0.2em;
}

.logoImg {
  width: 40px;
  height: auto;
}

.toggle {
  margin: 0 18px;
  fill: white;
  width: 32px !important;
  height: 32px !important;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

.userContainer {
  margin-left: auto;
  padding-right: 32px;
}

.signOut {
  cursor: pointer;
  color: black !important;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

.sideNavToggle {
  position: absolute !important;
  left: calc(100% - 20px);
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
  top: 35px;
  font-size: 26px !important;
  box-shadow: 0px 0px 2px 1px rgba(230, 230, 230, 0.75);
}

.sideNav {
  display: inline-flex;
  min-height: 100vh;
}

.sideNavContainer {
  flex-grow: 1;
  background-color: white;
  border-right: 2px solid #EEF0F3;
  flex-direction: column;
  position: relative;
}

.sideNavContainerOpen {
  min-width: 220px;
}

.sideNavContainerClosed {
  width: auto;
}
.navItem {
  margin: 8px 14px;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
}

.navItem.link {
  cursor: pointer;

  &:hover {
    color: var(--chakra-colors-action);
    background-color: #F4F4F4;
  }
}

.navItem:hover {
  > .navItemText {
    opacity: 1 !important;
  }

  > .icon {
    opacity: 1 !important;
  }
}

.navItemUsers {
  margin-top: auto;
  padding-bottom: 24px;
}

.navItemTextActive {
  opacity: 1 !important;
}
.navItemText {
  font-size: 16px;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.icon {
  opacity: 0.6;
  width: 32px !important;
  height: 32px !important;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.iconActive {
  opacity: 1 !important;
}

.dividerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #3f3f3f;
}

.navItemTextHidden {
  display: none;
}

.navItemActive {
  color: var(--chakra-colors-action);
  background-color: #F4F4F4;
}

.footer {
  width: 100%;
  height: 40px;
  background: rgb(79, 68, 224);
  // background: linear-gradient(
  //   90deg,
  //   rgba(116, 108, 221, 1) 0%,
  //   rgba(102, 93, 224, 1) 50%,
  //   rgba(79, 68, 224, 1) 100%
  // );
}
