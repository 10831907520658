@import "../Global.module.scss";

.container {
  display: flex;
  flex-direction: column;
}

.content {
  padding: 12px;
}

.textArea {
  outline: 1px solid rgb(232, 232, 232);
  width: 100%;
  resize: none;
  height: 100px;
  padding: 8px;
}

.textAreaContainer {
  z-index: 1;
}

.helpContainer {
  padding: 32px 0;
  width: 100%;
}

.tabs {
  width: 100%;
}

.helperDescriptionContainer {
  height: 100%;
}

.helperDescriptionContainer,
.testContainer {
  margin: 0;
  padding: 0 16px;
}

.testVariables {
  border-right-width: 1px;
  padding-right: 16px;
  max-height: 400px;
  overflow-y: scroll;
}

.testVariables,
.testOutputContainer {
  flex: 1;
  flex-direction: column;
}

.testVariableContainer {
  padding: 0 0 16px 0;
  flex-direction: column;

  &:last-child {
    padding-bottom: 0;
  }
}

.helperDescription,
.testContainer {
  flex-direction: column;
}

.divider {
  border-left-width: 4px;
  height: 180px;
  margin: 0;
  padding: 0 8px;
}

.testOutputContainer {
  padding: 0 16px;
  align-items: center;
  width: 80%;
  flex-direction: column;
}

.testButton {
  width: 100%;
}

.testResult {
  margin: 24px 0;
  padding: 8px 16px !important;
  width: 100%;
}
