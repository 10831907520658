@import "../Global.module.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  margin: 16px 0 0 0;
}
