@import "../Global.module.scss";

.destinationBox {
  margin: 0 12px 0 0;
}

.destinationForm {
  padding: 24px 0;
}

.actionContainer {
  justify-content: space-between;
}