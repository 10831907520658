@import "../../../Global.module.scss";

.input {
  background-color: inherit !important;
}

.selectDataSourceContainer {
  display: flex;
  flex-direction: column;
  // width: 90vw;
  margin-left: 0;
  margin-right: 0;
  gap: 1rem;
}

.btnContainer {
  justify-content: space-between;
}

.progressContainer {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.progressContainerVisible {
  opacity: 1;
  margin: 1rem 0;
}

.progressBar {
  width: 100%;
}
