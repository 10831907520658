.dropzone {
  overflow: hidden;
  border: 1px dashed;
  margin: 0 0 24px 0;
  background-color: white;
}

.dropzoneInputContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropzoneInputLabel {
  cursor: pointer;
  color: #4f44e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  font-weight: 800;
}

.dropzoneInputLabel > div > svg {
  width: 32px;
}

.configureUploadContainer {
  display: flex;
  flex-direction: column;
  padding: 48px;
  width: 100%;
}

.smallText {
  font-size: 0.8rem;
}