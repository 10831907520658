@import "../Global.module.scss";

.header {
  background-color: #F1F3FA;
}

.paginatedTableContainer {
  flex-direction: column;
  width: 100%;
}

.td {
  padding: 12px 16px !important;
  max-width: 120px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.th {
  padding: 12px 16px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
}

.actions {
  padding: 12px;
  max-width: 160px;
  width: 80px;
}

.td > div {
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.table {
  border: 1px solid var(--chakra-colors-chakra-border-color);
  border-radius: var(--chakra-radii-xl);
  width: 100%;
}

.tableBody {
  background-color: white;
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  background-color: var(--chakra-colors-chakra-border-color);
}

.activePage {
  border: 1px solid black !important;
  color: black !important;
}

.paginationItem {
  border: 1px solid var(--chakra-colors-chakra-border-color);
  border-radius: 8px;
  padding: 4px 12px;
  margin: 4px;
  color: var(--chakra-colors-gray-500);
  transition: all 0.3s ease-in-out;
}

.paginationItem:hover {
  border: 1px solid black !important;
  color: black !important;
  cursor: pointer;
}

.tableContainer {
  flex-direction: column;
  width: 100%;
}

.paginationContainer {
  justify-content: flex-end;
  margin: 8px 0;
}

.readOnlyCell {
  background-color: #f6f6f6;
  color: #737373;
  max-width: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
}

.readOnlyCell > div {
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.spacing {
  padding: 12px 16px;
}

.rowsLabel {
  font-size: 14px;
}

.disableBorder {
  border: none !important;
}

.paginationList {
  margin-bottom: 0;
}

.input {
  margin: 0;
  padding: 0 8px !important;
}

.checkboxInput {
  padding: 0;
  margin: 0 8px !important;
}

// data type specific styling
.th.BOOLEAN {
  width: 40px !important;
}
