@import "../../../Global.module.scss";

.validationError {
  width: 100%;
  & input {
    border: 1px solid var(--chakra-colors-red-500) !important;
  }
}

.tableInput {
  padding: 0 !important;
}

.reviewTable {
  border-bottom: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  th:not(:last-child), td:not(:last-child) {
    border-right: 1px solid var(--chakra-colors-chakra-border-color);
  }

  th {
    padding: 12px 10px !important;
  }

  td {
    padding: 0 !important;

    input {
      padding: 12px 10px !important;
      border-radius: 0;
    }
  }
}
