@import "../../../Global.module.scss";

.reviewAndFinalizeContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.btnContainer {
  justify-content: space-between;
}