@import "../Global.module.scss";

.addIcon {
  margin: 4px;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.loadMoreBtn {
  cursor: pointer;
  color: var(--chakra-colors-action)
}
