@import "../Global.module.scss";

.selector {
    cursor: pointer;
}

.selectorActive {
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.errorMessage {
    padding: 8px;
    color: rgb(196, 0, 0);
}

.deleteBtn {
    width: 160px;
}

.label {
    font-weight: bold !important;
    font-size: 1.2em !important;
}

.tabList {
    display: inline-flex;
    gap: 1rem;

    & > * {
        border-bottom: 2px solid #E2E8F0;
    }

    & > *[aria-selected="true"] {
        color: var(--chakra-colors-action)
    }
}