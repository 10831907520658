@import "../../../Global.module.scss";

.uploadContainer {
    gap: 2rem;
    margin-bottom: 2rem;
}

.expectedColumnsTable {
    max-height: 400px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    
    th {
        width: 50%;
    }
}

.dataTableContainer {
    margin-bottom: 1.5rem;
}

.progressContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 0 0;
}

.progressBar {
  width: 100%;
}