.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
}

.box {
  margin: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  cursor: pointer;
  color: #818181;
  transition: all 0.3s ease-in;
}

.box:hover {
  > .icon {
    fill: black;
  }
  color: black;
  border-color: black;
}
.icon {
  width: 128px;
  height: 128px;
  margin: 0 0 24px 0;
  fill: #818181;
  transition: all 0.3s ease-in;
}
