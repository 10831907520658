@import "../Global.module.scss";

.addColumnBtn {
  width: 100%;
  // border-bottom: 1px solid var(--chakra-colors-chakra-border-color);
  transition: all 0.3s ease-in-out;
}

.form {
  display: flex;
  flex-direction: column;
}

.addColumnBtn:hover {
  cursor: pointer;

  >.addIcon {
    opacity: 1;
  }
}

.addIcon {
  margin: 4px;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.btnFlexContainer {
  margin-top: 24px;
  justify-content: space-between;
}

.createUpdateBtnContainer {
  width: 25%;
  flex-direction: column;
}

.createBtn {
  margin-top: 16px;
}

.createFromSelector {
  padding-right: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.createFromSelectorActive {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.createFromContainer {
  padding-top: 24px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: center;
}

.actionBtn {
  padding: 0 4px;
  margin: 0 4px;
}

.input {
  margin: 0;
  padding: 0 4px !important;
}

.actionsArrowContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.actionArrow {
  width: 10px;
  height: 20px !important;
}

.actionArrow.firstRow {
  margin-top: auto;
}