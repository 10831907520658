.container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(116, 108, 221);
  background: linear-gradient(
    90deg,
    rgba(116, 108, 221, 1) 0%,
    rgba(102, 93, 224, 1) 50%,
    rgba(79, 68, 224, 1) 100%
  );
}

@media only screen and (max-width: 600px) {
  .loginBox {
    width: 80vw !important;
    padding: 32px !important;
  }
}
.loginBox {
  background-color: white;
  padding: 64px;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginHeading {
  padding: 32px 0;
  text-align: center;
  color: #4f44e0;
  font-size: 48px !important;
  font-weight: normal !important;
  font-family: Katibeh, system-ui, sans-serif !important;
}

.inputsContainer {
  width: 100%;
}

.loginInput {
  margin: 8px 0;
  width: 100%;
  background-color: white;
}

.databaseImg {
  width: 64px;
  height: auto;
}

.loginBtn {
  width: 100%;
}

.signInError {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  color: rgb(196, 0, 0);
}
