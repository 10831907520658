@import "../../Global.module.scss";

.disclaimer {
  height: 100px;
}

.previewTable {
  width: 100%;
  margin: 0 0 16px 0;
}
